<template>
  <div class="m-jeff-bag">
    <aSection :title="section.title" :subtitle="section.subtitle">
      <div class="container bag">
        <div class="bag__image">
          <img src="@/assets/images/webp/bag_2.webp" alt="jeffbag" />
        </div>
        <div class="bag__details">
          <h3 class="bag__details--title">{{ $t(details.title) }}</h3>
          <p class="bag__details--description">
            {{ $t(details.description) }}
          </p>
          <ul class="bag__details--list">
            <li
              v-for="(item, i) in details.list"
              :key="`bag-item-${i}`"
              class="bag__details--list-item"
            >
              <SfIcon icon="check" />
              <span>{{ $t(item) }}</span>
            </li>
          </ul>
          <div class="bag__details--note">
            <SfIcon icon="info_circle" />
            <p>
              {{ $t(details.note)
              }}<span>{{ $t("întrebări și răspunsuri") }}</span>
            </p>
          </div>
        </div>
      </div>
      <mBagPrices />
    </aSection>
  </div>
</template>
<script>
import { SfIcon } from "@storefront-ui/vue";
import aSection from "../atoms/a-section.vue";
import mBagPrices from "../molecules/m-bag-prices.vue";

export default {
  components: {
    aSection,
    SfIcon,
    mBagPrices,
  },
  data() {
    return {
      section: {
        title: "Spălat și împăturit, fără bătăi de cap",
        subtitle: "Sacoșa Jeff",
      },
      details: {
        title: "Descoperă Sacoșa de Spălătorie Mr Jeff!",
        description:
          "Mr Jeff îți oferă un serviciu simplu și eficient pentru a-ți face viața mai ușoară. Tot ce trebuie să faci este să umpli o sacoșă de spălătorie Mr Jeff (oferită de noi) cu hainele tale murdare, iar noi ne ocupăm de restul. Serviciul nostru include spălatul și împăturitul hainelor tale, astfel încât tu să te poți bucura de haine curate și proaspăt împăturite, fără niciun efort.",
        list: [
          "Spălăm și împăturim hainele tale, oferindu-ți mai mult timp liber.",
          "Luăm hainele de la ușă și le returnăm curate și împăturite. Umple sacoșa și gata!",
          "Folosim detergenți și echipamente de top pentru cea mai bună îngrijire a hainelor tale.",
        ],
        note:
          "Sacoșa Mr Jeff nu include călcatul, curățarea uscată sau tratamentele speciale. Pentru mai multe detalii, consultați secțiunea de ",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-jeff-bag {
  .bag {
    padding: var(--spacer-sm) 0;
    display: flex;
    align-items: center;
    gap: var(--spacer-2xl);
    @include for-mobile {
      flex-wrap: wrap;
      padding: var(--spacer-sm);
      max-width: calc(100% - 2 * var(--spacer-sm));
      gap: var(--spacer-xl);
    }

    &__details {
      flex: 1 1 50%;
      &--title {
        font-size: 1.75rem;
        font-weight: var(--font-semibold);
        margin: 0 0 var(--spacer-base) 0;
        @include for-mobile {
          font-size: var(--font-lg);
          margin: var(--spacer-sm) 0 var(--spacer-xs) 0;
        }
      }
      &--description {
        font-size: var(--font-base);
        line-height: 1.4;
        margin: 0;
        &-container {
          margin: 0 0 var(--spacer-base) 0;
          &:last-child {
            margin: 0;
          }
        }
      }
      &--list {
        font-size: var(--font-base);
        line-height: 1.4;
        margin: 0;
        list-style: none;
        padding: 0;

        &-item {
          --icon-color: var(--c-primary);
          display: flex;
          align-items: center;
          margin-top: var(--spacer-base);
          span {
            margin-left: var(--spacer-sm);
          }
        }
      }
      &--note {
        --icon-color: var(--c-primary-variant);
        --icon-size: 1.25rem;
        display: flex;
        align-items: center;
        opacity: 0.8;
        margin-top: var(--spacer-2xl);
        p,
        span {
          color: var(--c-gray-lighten);
          font-size: var(--font-sm);
        }
        p {
          margin: 2px 0 0 var(--spacer-xs);
        }
        span {
          // text-decoration: underline;
          // cursor: pointer;
        }
      }
    }
    &__image {
      flex: 1 1 50%;
      border-radius: 10px;
      overflow: hidden;
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow-clip-margin: unset;
      }
    }
  }
}
</style>
