<template>
  <div class="m-subscription">
    <div class="m-subscription__header">
      <h4 class="m-subscription__title">
        {{ $t(subscription.title) }}
      </h4>
    </div>
    <div class="m-subscription__content">
      <h4 class="m-subscription__extra">{{ $t("Servicii") }}</h4>
      <div
        v-for="(service, i) in subscription.prices"
        :key="`service-${i}`"
        class="m-subscription__row"
      >
        <p class="m-subscription__description">{{ $t(service.title) }}</p>
        <p class="m-subscription__price">{{ service.price }}</p>
      </div>
      <h4 class="m-subscription__extra">{{ $t("Extra") }}</h4>
      <div
        v-for="(service, i) in subscription.extra"
        :key="`extra-${i}`"
        class="m-subscription__row"
      >
        <p class="m-subscription__description">{{ $t(service.title) }}</p>
        <p class="m-subscription__price">{{ service.price }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.m-subscription {
  margin: var(--spacer-sm);
  filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
  &__header {
    background-color: var(--c-primary-darken);
    padding: var(--spacer-sm) var(--spacer-base);
    border-radius: 10px 10px 0 0;
  }
  &__title {
    margin: 0;
    font-size: var(--font-base);
    font-weight: var(--font-semibold);
    color: var(--c-white);
  }
  &__content {
    padding: var(--spacer-base);
    border-radius: 0 0 10px 10px;
    background-color: var(--c-light);
  }
  &__extra {
    margin: var(--spacer-sm) 0 0;
    color: var(--c-dark);
    font-size: var(--font-sm);
    font-weight: var(--font-semibold);
    line-height: 1.2;
    &:first-child {
      margin: 0;
    }
  }
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: var(--spacer-2xs) 0;
  }
  &__description {
    margin: 0;
    font-size: var(--font-sm);
    font-weight: var(--font-normal);
    line-height: 1.2;
  }
  &__price {
    margin: 0;
    color: var(--c-primary-darken);
    font-size: var(--font-sm);
    font-weight: var(--font-bold);
    line-height: 1.2;
  }
}
</style>
