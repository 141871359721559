<template>
  <div class="o-prices">
    <div class="container prices">
      <div class="prices__categories">
        <img
          src="@/assets/images/webp/fold.webp"
          alt="fold"
          aria-hidden="true"
          class="prices__categories--background"
        />
        <div
          v-for="category in categories"
          :key="category.id"
          class="prices__category"
          :class="{
            'prices__category--active': category.id === activeCategory,
          }"
          @click="setCategory(category.id)"
        >
          <div class="prices__category--icon">
            <SfIcon :icon="category.icon" />
          </div>
          <span class="prices__category--title">{{ $t(category.title) }}</span>
        </div>
      </div>
      <div class="prices__products" :key="activeCategory">
        <div class="prices__products__wrapper">
          <div
            v-for="(product, i) in getProducts"
            :key="`product-${i}`"
            class="prices__product"
          >
            <div class="prices__product--details">
              <div class="prices__product--icon">
                <img :src="getIcon(product.icon)" />
              </div>
              <p class="prices__product--name">{{ $t(product.name) }}</p>
            </div>
            <span class="prices__product--price">{{
              `${product.price} lei`
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SfIcon } from "@storefront-ui/vue";
const prices = require("../../store/resources/prices.json");

export default {
  components: {
    SfIcon,
  },
  data() {
    return {
      categories: prices,
      activeCategory: "",
    };
  },
  mounted() {
    this.setCategory();
  },
  computed: {
    getProducts() {
      let activeCategory = this.categories.find(
        (category) => category.id === this.activeCategory
      );

      return activeCategory?.products;
    },
  },
  methods: {
    setCategory(category) {
      category
        ? (this.activeCategory = category)
        : (this.activeCategory = this.categories[0].id);
    },
    getIcon(icon) {
      return require(`@/assets/icons/clothes/${icon}.png`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-prices {
  padding: var(--spacer-sm) 0;
  .prices {
    display: flex;
    gap: var(--spacer-lg);

    @include for-desktop {
      height: 36rem;
    }
    @include for-mobile {
      flex-direction: column;
      margin: 0 var(--spacer-sm);
      width: calc(100% - 2rem);
    }

    &__categories {
      position: relative;
      flex: 1 1 40%;
      background-color: var(--c-white);
      border-radius: 10px;
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));
      overflow: hidden;
      @include for-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &--background {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 65%;
        height: 65%;
        object-fit: cover;
        overflow-clip-margin: unset;
        opacity: 0.4;
        @include for-mobile {
          display: none;
        }
      }
    }
    &__category {
      @include for-mobile {
        padding: var(--spacer-sm);
        flex: 1 1 calc(50% - 2rem);
      }
      position: relative;
      display: flex;
      align-items: center;
      padding: var(--spacer-base) var(--spacer-lg);
      cursor: pointer;
      transition: 0.3s ease;
      &:nth-child(2) {
        &::before {
          content: none !important;
        }
      }
      &:not(:first-child) {
        &::before {
          content: "";
          position: absolute;
          width: calc(100% - var(--spacer-lg) * 2);
          height: 1px;
          top: 0;
          left: var(--spacer-lg);
          background-color: var(--c-light);
        }
      }
      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          width: calc(100% - var(--spacer-lg) * 2);
          height: 1px;
          bottom: 0;
          left: var(--spacer-lg);
          background-color: var(--c-light);
        }
      }
      &:hover,
      &--active {
        background-color: var(--c-primary-darken);
        mix-blend-mode: multiply;
        .prices__category {
          &--icon {
            --icon-color: var(--c-white);
          }
          &--title {
            color: var(--c-white);
          }
        }
      }
      &--icon {
        --icon-size: 2.25rem;
        --icon-color: var(--c-primary-darken);
        margin-right: var(--spacer-sm);
        @include for-mobile {
          --icon-size: 1.75rem;
        }
      }
      &--title {
        font-size: var(--font-lg);
        font-weight: var(--font-normal);
        @include for-mobile {
          font-size: var(--font-sm);
        }
      }
    }

    &__products {
      @include for-desktop {
        flex: 1 1 60%;
      }
      @include for-mobile {
        height: 36rem;
      }
      background-color: var(--c-white);
      border-radius: 10px;
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));
      overflow: hidden;
      &__wrapper {
        height: 36rem;
        padding: var(--spacer-sm) var(--spacer-lg);
        overflow: scroll;
        &::-webkit-scrollbar {
          width: 0.35rem;
        }
        &::-webkit-scrollbar-track {
          background: var(--c-light);
        }
        &::-webkit-scrollbar-thumb {
          background: var(--c-primary-darken);
        }
      }
    }
    &__product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--spacer-xs) 0;
      &:not(:last-child) {
        border-bottom: 1px solid var(--c-light);
      }
      &--details {
        display: flex;
        align-items: center;
        gap: var(--spacer-sm);
      }
      &--icon {
        width: 2.25rem;
        height: 2.25rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow-clip-margin: unset;
        }
      }
      &--title {
        position: relative;
        margin: 0;
        padding-top: var(--spacer-sm);
        font-weight: var(--font-light);
        font-size: var(--font-base);
      }
      &--price {
        display: block;
        font-weight: var(--font-medium);
        font-size: var(--font-sm);
        color: var(--c-primary-darken);
      }
    }
  }
}
</style>
