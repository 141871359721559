<template>
  <div class="m-bag-prices">
    <aSection :title="section.title">
      <div class="container">
        <div class="subscription-type">
          <button
            :class="{ 'subscription-type--active': !bag_subscription }"
            @click="bag_subscription = false"
          >
            {{ $t("Fără abonament") }}
          </button>
          <button
            :class="{ 'subscription-type--active': bag_subscription }"
            @click="bag_subscription = true"
          >
            {{ $t("Cu abonament") }}
          </button>
        </div>
        <div class="subscriptions">
          <div v-for="(bag, i) in bags" :key="`bag-${i}`" class="subscription">
            <h3 class="subscription__title">{{ $t(bag.title) }}</h3>
            <div class="subscription__details">
              <span class="subscription__details--price">{{
                `${bag_subscription ? bag.price_subscription : bag.price} lei`
              }}</span>
              <p class="subscription__details--description">
                <span>{{ $t(`${bag.name} /`) }}</span>
                <span>{{
                  bag_subscription ? $t("cu abonament") : $t("fără abonament")
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </aSection>
  </div>
</template>
<script>
import aSection from "../atoms/a-section.vue";

export default {
  components: {
    aSection,
  },
  data() {
    return {
      section: {
        title: "Alege sacoșa care ți se potrivește",
        subtitle: "Sacoșa Jeff",
      },
      bag_subscription: false,
      bags: [
        {
          title: "Standard (~5kg)",
          name: "Pungă standard",
          price: 40,
          price_subscription: 34,
        },
        {
          title: "Mare (~10kg)",
          name: "Pungă mare",
          price: 60,
          price_subscription: 50,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-bag-prices {
  & .a-section {
    padding: var(--spacer-sm) 0 !important;
  }
  .subscription-type {
    display: flex;
    justify-content: center;
    align-items: center;
    @include for-mobile {
      padding: 0 var(--spacer-sm);
    }
    &--active {
      background-color: var(--c-primary-darken) !important;
      color: var(--c-white) !important;
    }
    button {
      width: 14rem;
      color: var(--c-primary-darken);
      font-weight: var(--font-medium);
      font-size: var(--font-sm);
      outline: none;
      border: 2px solid var(--c-primary-darken);
      border-radius: 100px;
      margin: var(--spacer-sm) 0 0;
      padding: var(--spacer-xs) var(--spacer-lg);
      font-family: var(--font-family-primary);
      background-color: var(--c-light);
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      transition: 0.3s ease;
      cursor: pointer;
      &:hover {
        background-color: var(--c-light-darken);
      }
      &:first-child {
        border-radius: 100px 0 0 100px;
        border-right: 0;
      }
      &:last-child {
        border-radius: 0 100px 100px 0;
        border-left: 0;
      }
    }
  }
  .subscriptions {
    padding-top: var(--spacer-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    @include for-mobile {
      flex-wrap: wrap;
    }
    .subscription {
      background-color: var(--c-white);
      width: 26rem;
      border-radius: 10px;
      margin: var(--spacer-sm);
      font-family: var(--font-family-primary);
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      overflow: hidden;
      &__title {
        margin: 0 0 var(--spacer-sm);
        padding: var(--spacer-sm) var(--spacer-lg);
        background-color: var(--c-primary-darken);
        color: var(--c-white);
      }
      &__details {
        display: flex;
        align-items: center;
        padding: var(--spacer-xl) var(--spacer-lg) var(--spacer-lg);
        &--price {
          margin: 0;
          font-size: 2.5rem;
          font-weight: var(--font-bold);
          color: var(--c-primary-darken);
        }
        &--description {
          margin: 0 0 0 var(--spacer-sm);
          font-size: var(--font-sm);
          span {
            display: block;
          }
        }
      }
    }
  }
}
</style>
