<template>
  <aSection
    class="m-prices"
    :title="section.title"
    :subtitle="section.subtitle"
  >
    <oPrices />
  </aSection>
</template>
<script>
import aSection from "../atoms/a-section.vue";
import oPrices from "../organisms/o-prices.vue";

const categories = require("../../store/resources/prices.json");

export default {
  components: {
    aSection,
    oPrices,
  },
  data() {
    return {
      section: {
        subtitle: "Prețuri",
        title: "Profită de prețurile avantajoase",
      },
      categories: categories,
      activeCategory: "",
    };
  },
  mounted() {
    this.setCategory();
  },
  computed: {
    getProducts() {
      let activeCategory = this.categories.find(
        (category) => category.id === this.activeCategory
      );

      return activeCategory?.products;
    },
  },
  methods: {
    setCategory(category) {
      category
        ? (this.activeCategory = category)
        : (this.activeCategory = this.categories[0].id);
    },
  },
};
</script>
<style lang="scss" scoped>
.m-prices {
}
</style>
