<template>
  <div class="m-services">
    <aSection
      :title="section.title"
      :subtitle="section.subtitle"
      :background="'#fff'"
    >
      <div class="container">
        <div class="subscriptions desktop-only">
          <div class="subscriptions__header">
            <h4
              v-for="(title, i) in subscriptions.header"
              :key="`header-title-${i}`"
              class="subscriptions__header--item"
            >
              {{ $t(title) }}
            </h4>
          </div>
          <div class="subscriptions__content">
            <div
              v-for="(subscription, i) in subscriptions.content"
              :key="`subscription-${i}`"
              class="subscription"
            >
              <p class="subscription__item subscription__item--title">
                {{ $t(subscription.title) }}
              </p>
              <p class="subscription__item subscription__item--price">
                {{
                  subscription.prices.wash_iron
                    ? `${subscription.prices.wash_iron} lei`
                    : ``
                }}
              </p>
              <p class="subscription__item subscription__item--price">
                {{
                  subscription.prices.wash_only
                    ? `${subscription.prices.wash_only} lei`
                    : ``
                }}
              </p>
              <p class="subscription__item subscription__item--price">
                {{
                  subscription.prices.iron_only
                    ? `${subscription.prices.iron_only} lei`
                    : ``
                }}
              </p>
              <p class="subscription__item subscription__item--price">
                {{
                  subscription.prices.transport
                    ? `${subscription.prices.transport}`
                    : ``
                }}
              </p>
              <p class="subscription__item subscription__item--price">
                <span
                  v-for="(price, i) in getExtraPrices(subscription.title)"
                  :key="`price-extra-${i}`"
                >
                  {{ price }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="subscriptions mobile-only">
          <mSubscription
            v-for="(subscription, i) in subscriptionsMobile"
            :key="`subscription-${i}`"
            :subscription="subscription"
          />
        </div>
        <div class="subscriptions__faq" ref="faq">
          <div
            v-for="(item, i) in subscriptions.faq"
            :key="`question-${i}`"
            :ref="`question-${i}`"
            class="subscriptions__faq--question"
            @click="setActiveQuestion(i)"
          >
            <div class="subscriptions__faq--question-icon">
              <SfIcon
                :icon="subscriptions.activeQuestion === i ? 'minus' : 'plus'"
              />
            </div>
            <div class="subscriptions__faq--question-container">
              <h4>{{ $t(item.question) }}</h4>
              <div
                class="subscriptions__faq--answer-container"
                :ref="`answer-container-${i}`"
              >
                <p class="subscriptions__faq--answer" :ref="`answer-${i}`">
                  <span v-for="(answer, j) in item.answer" :key="`answer-${j}`">
                    {{ $t(answer) }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aSection>
  </div>
</template>
<script>
import aSection from "../atoms/a-section.vue";
import mSubscription from "../molecules/m-subscription.vue";
import { SfIcon } from "@storefront-ui/vue";

export default {
  components: {
    aSection,
    mSubscription,
    SfIcon,
  },
  data() {
    return {
      section: {
        title: "Profită de serviciile și abonamentele noastre",
        subtitle: "Servicii și abonamente",
      },
      subscriptionsMobile: [
        {
          title: "Abonament 24 articole",
          prices: [
            {
              title: "Spălat + Călcat",
              price: "200 lei",
            },
            {
              title: "Doar călcat",
              price: "140 lei",
            },
            {
              title: "Transport",
              price: this.$t("40 lei (max. 4 comenzi)"),
            },
          ],
          extra: [
            {
              title: "Articol extra",
              price: "12 lei",
            },
          ],
        },
        {
          title: "Abonament S (4 sacoșe standard) aprox. 20kg",
          prices: [
            {
              title: "Doar spălat",
              price: "120 lei",
            },
            {
              title: "Transport",
              price: this.$t("40 lei (max. 4 comenzi)"),
            },
          ],
          extra: [
            {
              title: "Sacoșă standard",
              price: "34 lei",
            },
            {
              title: "Sacoșă mare",
              price: "50 lei",
            },
          ],
        },
        {
          title: "Abonament M (4 sacoșe mari) aprox. 40kg",
          prices: [
            {
              title: "Doar spălat",
              price: "195 lei",
            },
            {
              title: "Transport",
              price: this.$t("40 lei (max. 4 comenzi)"),
            },
          ],
          extra: [
            {
              title: "Sacoșă standard",
              price: "34 lei",
            },
            {
              title: "Sacoșă mare",
              price: "50 lei",
            },
          ],
        },
        {
          title: "Pachet 12 articole",
          prices: [
            {
              title: "Spălat + Călcat",
              price: "125 lei",
            },
            {
              title: "Doar călcat",
              price: "95 lei",
            },
            {
              title: "Transport",
              price: "15 lei",
            },
          ],
          extra: [
            {
              title: "Articol extra",
              price: "12 lei",
            },
          ],
        },
        {
          title: "Pachet 6 articole",
          prices: [
            {
              title: "Spălat + Călcat",
              price: "65 lei",
            },
            {
              title: "Doar Călcat",
              price: "50 lei",
            },
            {
              title: "Transport",
              price: "15 lei",
            },
          ],
          extra: [
            {
              title: "Articol extra",
              price: "12 lei",
            },
          ],
        },
      ],
      subscriptions: {
        activeQuestion: 999,
        header: [
          "Abonament / Pachet servicii",
          "Spălat + Călcat",
          "Doar spălat",
          "Doar călcat",
          "Transport",
          "Extra",
        ],
        content: [
          {
            title: "Abonament 24 articole",
            prices: {
              wash_iron: "200",
              iron_only: "140",
              transport: this.$t("40 lei (max. 4 comenzi)"),
              extra: [this.$t("Articol extra - 12 lei")],
            },
          },
          {
            title: "Abonament S (4 sacoșe standard) aprox. 20kg",
            prices: {
              wash_only: "120",
              transport: this.$t("40 lei (max. 4 comenzi)"),
              extra: [
                this.$t("Sacoșă standard - 34 lei"),
                this.$t("Sacoșă mare - 50 lei"),
              ],
            },
          },
          {
            title: "Abonament M (4 sacoșe mari) aprox. 40kg",
            prices: {
              wash_only: "195",
              transport: this.$t("40 lei (max. 4 comenzi)"),
              extra: [
                this.$t("Sacoșă standard - 34 lei"),
                this.$t("Sacoșă mare - 50 lei"),
              ],
            },
          },
          {
            title: "Pachet 12 articole",
            prices: {
              wash_iron: "125",
              iron_only: "95",
              transport: "15 lei",
              extra: [this.$t("Articol extra - 12 lei")],
            },
          },
          {
            title: "Pachet 6 articole",
            prices: {
              wash_iron: "65",
              iron_only: "50",
              transport: "15 lei",
              extra: [this.$t("Articol extra - 12 lei")],
            },
          },
        ],
        faq: [
          {
            question:
              "Care este diferența dintre Pachetele de Servicii și Abonamente?",
            answer: [
              `- Pachetul de Servicii va fi completat o singură dată (ex: Dacă ai ales "Pachet 6 articole", va trebui să aduci toate cele 6 articole în aceeași zi).`,
              `- Abonamentul îl poți completa timp de o lună ori de câte ori dorești (ex: Dacă ai ales "Abonament 24 articole", ai la dispoziție o lună de zile pentru a aduce cele 24 de articole, de câte ori dorești).`,
            ],
          },
          {
            question:
              "Ce tipuri de articole sunt incluse în abonamentele, pachetele sau sacoșa Mr Jeff?",
            answer: [
              "Abonamentele și pachetele cuprind doar articolele de zi cu zi, care nu necesită tratament special sau curățare uscată.",
            ],
          },
          {
            question:
              "Cum se tarifează articolele care necesită tratament special sau curățare uscată?",
            answer: [
              "Articolele care necesită tratament special sau curățare uscată se vor tarifa separat, la prețul indicat în lista noastră de prețuri, cu acordul tău.",
            ],
          },
          {
            question:
              "Care sunt câteva exemple de articole care nu sunt incluse în abonamentele, pachetele sau sacoșa Mr Jeff?",
            answer: [
              "Exemple de articole care nu vor fi incluse în abonamente / pachete: sacou, costum, haină blană / piele, cojoc, geacă, pantofi, pilotă, pătură, articole de ocazie care necesită tratament special (rochie, fustă, bluză, etc.), etc.",
            ],
          },
          {
            question:
              "Care este valabilitatea abonamentului, cum este utilizat și cum sunt taxate articolele suplimentare?",
            answer: [
              `Abonamentul este valabil 30 zile. Nu este obligatoriu să aduci toate articolele în aceeași zi. Le poți aduce gradual, ori de câte ori dorești pe parcursul celor 30 de zile. Dacă ai depășit numărul de articole din abonamentul ales, ce este în plus va fi tarifat la prețul indicat la "articol extra" sau "sacoșă extra", în funcție de abonamentul ales.`,
            ],
          },
        ],
      },
    };
  },
  // mounted() {
  //   this.$watch(
  //     "$route.hash",
  //     (val) => {
  //       console.log("val", val);
  //       if (val === "#intrebari-si-raspunsuri") {
  //         this.scrollToFaq();
  //       }
  //     },
  //     { immediate: true }
  //   );
  // },
  methods: {
    // scrollToFaq() {
    //   this.$nextTick(() => {
    //     const faqScrollPosition = this.$refs["faq"].getBoundingClientRect().top;
    //     console.log(faqScrollPosition);
    //     window.scrollTo({ top: faqScrollPosition });
    //   });
    // },
    setActiveQuestion(question) {
      const answerHeight = this.$refs[`answer-${question}`][0].clientHeight;

      if (this.subscriptions.activeQuestion === question) {
        this.subscriptions.activeQuestion = 999;
        this.$refs[`answer-container-${question}`][0].style.height = `0`;
      } else if (
        this.subscriptions.activeQuestion !== question &&
        this.subscriptions.activeQuestion !== 999
      ) {
        this.$refs[
          `answer-container-${this.subscriptions.activeQuestion}`
        ][0].style.height = `0`;
        this.subscriptions.activeQuestion = question;
        this.$refs[
          `answer-container-${question}`
        ][0].style.height = `calc(1rem + ${answerHeight}px)`;
      } else {
        this.subscriptions.activeQuestion = question;
        this.$refs[
          `answer-container-${question}`
        ][0].style.height = `calc(1rem + ${answerHeight}px)`;
      }
    },
    getExtraPrices(subscription) {
      let currentSubscription = this.subscriptions.content.find(
        (sub) => sub.title === subscription
      );

      return currentSubscription ? currentSubscription.prices.extra : [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-services {
  .subscriptions {
    margin-top: var(--spacer-xl);

    &__header {
      display: flex;
      align-items: center;
      background-color: var(--c-primary-darken);
      padding: var(--spacer-base) var(--spacer-lg);
      border-radius: 10px;
      &--item {
        flex: 1;
        margin: 0 var(--spacer-xs);
        line-height: 1.2;
        font-size: var(--font-base);
        color: var(--c-white);
        &:first-child {
          flex: 1 1 20%;
        }
        &:last-child {
          flex: 1 1 2%;
        }
      }
    }
    &__content {
      // display: flex;
      // align-items: center;
      // padding: var(--spacer-sm) var(--spacer-lg);
    }
    &__faq {
      margin-top: var(--spacer-lg);
      @include for-mobile {
        padding: 0 var(--spacer-sm);
      }
      &--question {
        display: flex;
        align-items: flex-start;
        padding: var(--spacer-base) 0;
        cursor: pointer;
        transition: 0.3s ease;
        overflow: hidden;
        &:not(:last-child) {
          border-bottom: 1px solid var(--c-primary-darken);
        }
        h4 {
          margin: 0;
          padding: var(--spacer-2xs) 0;
        }
        p {
          max-width: 90%;
          margin: var(--spacer-sm) 0 0 0;
          font-size: var(--font-sm);
          line-height: 1.6;
          overflow: hidden;
          span {
            display: block;
          }
        }
        &-icon {
          --icon-size: 1.125rem;
          --icon-color: var(--c-white);
          margin-right: var(--spacer-sm);
          padding: var(--spacer-2xs);
          border-radius: 200px;
          background-color: var(--c-primary-darken);
        }
      }
      &--answer {
        &-container {
          overflow: hidden;
          height: 0;
          transition: 0.3s ease;
        }
      }
    }
  }
  .subscription {
    display: flex;
    align-items: center;
    padding: var(--spacer-base) var(--spacer-lg);
    transition: 0.3s ease;
    &:not(:last-child) {
      border-bottom: 1px solid var(--c-light-darken);
    }
    &:hover {
      background-color: var(--c-light);
    }
    &__item {
      flex: 1;
      margin: 0 var(--spacer-xs);
      font-family: var(--font-family-primary);
      font-size: var(--font-sm);
      font-weight: var(--font-medium);
      &:first-child {
        flex: 1 1 20%;
      }
      &:last-child {
        flex: 1 1 2%;
      }
      span {
        display: block;
      }
    }
  }
}
</style>
