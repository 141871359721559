<template>
  <div class="services">
    <mPageTitle
      :title="$t(page.title)"
      :description="$t(page.description)"
      :image="require('@/assets/images/webp/services.webp')"
    />
    <mDelivery />
    <mJeffBag />
    <mServices />
    <div ref="prices_section">
      <mPrices />
    </div>
  </div>
</template>
<script>
import mPageTitle from "../components/molecules/m-page-title.vue";
import mPrices from "../components/molecules/m-prices.vue";
import mDelivery from "../components/molecules/m-delivery.vue";
import mJeffBag from "../components/molecules/m-jeff-bag.vue";
import mServices from "../components/molecules/m-services.vue";

export default {
  metaInfo() {
    return {
      title: this.$t("Servicii și Prețuri"),
    };
  },
  components: {
    mPageTitle,
    mPrices,
    mDelivery,
    mJeffBag,
    mServices,
  },
  data() {
    return {
      page: {
        title: "Servicii și Prețuri",
        description:
          "Cu servicii premium, tehnologie de ultimă oră și prețuri accesibile, transformăm modul în care îți întreții garderoba",
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });

    this.$watch(
      "$route.hash",
      (val) => {
        if (val === "#preturi") {
          this.scrollToPrices();
        }
      },
      { immediate: true }
    );
  },
  methods: {
    scrollToPrices() {
      const priceScrollPosition = this.$refs[
        "prices_section"
      ].getBoundingClientRect().top;
      window.scrollTo({ top: priceScrollPosition });
    },
  },
};
</script>
<style lang="scss" scoped>
.services {
}
</style>
